
$navBarHeight: 50;
$navBarHeightPx: $navBarHeight + px;

$navBarColorDark: #182746;
$navBarColorLighter: #283e6d;

.navbarHeight{
    height: $navBarHeightPx;
}

.bgDark{
    color: #fff !important;
    background-color: $navBarColorLighter!important;
}

.councilLogo {
    height: 25px;
}

.navbarLogo{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $navBarColorDark!important;
    @extend .sideBarWidth;
}

.navbarHeader{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
    font-weight: 500;
}

.sidebar {
    position: sticky;;
    top: $navBarHeightPx; 
    height: calc(100vh - $navBarHeightPx);
    bottom: 0;
    left: 0;
    z-index: 100;
    padding-left: 0!important;
    padding-right: 0!important;
    padding-top: 10px;
    padding-bottom: 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    background-color: rgba($navBarColorDark, 0.1) !important;

    @extend .sideBarWidth;
}

a.navLink {
    font-weight: 500;
    color: rgba($navBarColorDark, 0.7);

    &:focus {
        color: #0a58ca;
    }

    &Active{
        color: #0a58ca!important;
    }
}

.sideBarWidth{
    flex: 0 0 auto;
    width: 260px!important;
}

.verticallyAlign{
    display: inline-flex;
    align-items: center;
}