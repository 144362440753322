
a.viewButton {    
    color: #fff;
    background-color: #283e6d;
    border-color: #36476d;  

    &:hover {   
        color: #fff;                 
        background-color: #15213a;
        border-color: #15213a;  
    }

    svg {
        display: flex;
        margin: 3px;
    }
}

.table {
    * {
        font-size: 12px;
    }

    td {
        padding: 4px;
    }
}