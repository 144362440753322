.optionsContainer {
    display: flex;
    align-items: center;

    margin-right: 1rem;

    label{
        width: 90px;
    }

    select{
        width: 150px;
    }
}

.displayOptionsContainer{
    display: flex;
}
