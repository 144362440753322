
.allDrawingsHeading{
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.flexApart{
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    align-items: center;
    align-content: center;
}

.flexRight{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
}