
.form-control.is-invalid,
.form-control.is-valid {
    background-position: right 1.2rem center !important;
}

select.form-control{
    -webkit-appearance: auto!important;
    appearance: auto!important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}

button.btn {
    svg {
        width: 1em;
        display: inline;
        position: relative;
        top: 0.15em;
        vertical-align: unset;
    }
}

.btn-council-green {
    color: #fff;
    background-color: #377C2B;
    border-color: #377C2B; 
    
    &:hover {
        color: #fff;
        background-color: #2c6323;
        border-color: #2c6623;  
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgb(44 102 35 / 25%);
    }
}

.btn-council-red {
    color: #fff;
    background-color: #c02f3e;
    border-color: #c02f3e;
    
    &:hover {
        color: #fff;
        background-color: #85202a;
        border-color: #831f29; 
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem rgb(131 31 41 / 25%);
    }
}

.btn-council-yellow {
    color: rgb(63, 63, 63);
    background-color: #FEBF30;
    border-color: #FEBF30;
    
    &:hover {
        color: rgb(63, 63, 63);
        background-color: #c09124;
        border-color: #c09124; 
    }
    
    &:focus {
        box-shadow: 0 0 0 0.25rem rgb(192 145 36 / 25%);
    }
}


.btn-council-blue {
    color: #fff;
    background-color: rgb(0,103,144) !important;
    border-color: #1861ac !important;
    
    &:hover {
        color: #fff;
        background-color: rgb(0, 56, 78) !important;
        border-color: #104172 !important;
    }
}

.btn-dark-blue {
    color: #fff;
    background-color: #283e6d;
    border-color: #36476d;  

    &:hover {
        color: #fff;
        background-color: #15213a;
        border-color: #15213a;  
    }
}


.paginationContainer {

    .pagination {
        display: inline-block;
        text-align: center;
        display: inline-block;
        padding-left: 0;
        border-radius: 4px;
        margin: 0;

        li.disabled {
            a {
                color: rgb(143, 143, 143)!important;
            }
        }

        li {
            display: inline;

            a {
                float: left;
                color: #333333;
                padding: 5px 10px;
                text-decoration: none;
                border-radius: 15px;

                i {
                    font-size: 10px;
                }

                &.active {
                    background-color: #283e6d !important;
                    color: white !important;
                }
            }

            a:visited {
                color: inherit;
            }

            &.active {
                background-color: #283e6d !important;
                color: white !important;
            }
        }
    }
}