
.rowDrawingEditor{
    margin: 0!important;
    height: 100%;
}

.verticalLine{
    border-right: #74747426;
    border-right-style: solid;
    border-right-width: 1px;
}

.viewValueContainer > .viewValueRow {
    margin-top: 7px;
}

.viewValueContainer > .viewValueRow ~ .viewValueRow {
    margin-top: unset;
}

.viewValueLabel{
    margin-left: 13px;
}

.noAttachmentsMessage {
    margin-top: 1rem;
}

.attachmentError {
    margin-left: 1rem;
    font-weight: 600;
    color: #f44336;
}