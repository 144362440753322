
.parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.greyText {
    color:rgb(97, 97, 97);
}