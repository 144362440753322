%defaultBorder{
    border-style: solid!important;
    border-color: rgb(211, 211, 211)!important; 
}

.backButtonContainer{
    display: flex;
    justify-content: center;
    margin: 1rem;
}

.conditionCollection{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.mainBtnContainer{
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    button {
        width: 200px;
        margin-right: 1rem;
    }
}

.conditionGroupContainer{
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.conditionGroup{
    border: 1px;
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(212, 212, 212);

    margin: 1rem;
    margin-left: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    padding: 1rem;    
}

button.btnOr{
    color: #fff;
    background-color: #283e6d;
    border-color: #283e6d;

    &:focus {
        color: #fff;
        background-color: #283e6d;
        border-color: #283e6d;
        box-shadow: 0 0 0 0.25rem rgb(40 62 109 / 50%);
    }

    &:hover{
        color: #fff;
    }
}

button.btnAnd{
    color: #fff;
    background-color: #182746;
    border-color: #182746;

    &:focus {
        color: #fff;
        background-color: #182746;
        border-color: #182746;
        box-shadow: 0 0 0 0.25rem rgb(24 39 70 / 50%);
    }

    &:hover{
        color: #fff;
    }
}

.searchFieldContainer{
    display: flex;
    flex-direction: row;
    align-items: stretch;    
}

.searchFieldRow{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.marginRight{
    margin-right: 10px;
}

.inputField{
    margin-right: 10px;
    min-width: 207px;
    flex-grow: 100%;
}

.treeESContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    width: 25px;
}

.treeES{
    width: 100%;
    height: calc(50% - 8px);

    @extend %defaultBorder;
    border: 0px;
    border-top: 1px;
    border-left: 1px;
    border-top-left-radius: 5px;
}

.treeNEContainer{
    display: flex;
    flex-direction: column;

    width: 25px;
}

.treeNE{
    width: 100%;
    height: calc(50% + 8px);

    @extend %defaultBorder;  
    border: 0px;
    border-bottom: 1px;
    border-left: 1px;
    border-bottom-left-radius: 5px;
}

.treeNESContainer{
    display: flex;
    flex-direction: column;

    width: 25px;

    @extend %defaultBorder; 
    border: 0px;
    border-left: 1px;
}

.treeNES{
    width: 100%;
    height: calc(50% + 8px);

    @extend %defaultBorder; 
    border: 0px;
    border-bottom: 1px;
}

.flexApart{
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    align-items: center;
    align-content: center;
}

.flexRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem;
}