
.loadingBox {
    display: flex;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}

.loadingLabel {
    margin: 0;
    padding-left: 20px;
}