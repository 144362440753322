.searchBarContainer{
    margin: 1rem;
    display: flex;
    justify-content: center;    
}

.searchBarRow {
    display: flex;
    flex-wrap: wrap;
}

.searchButton {
    width: 130px;
}
   
.searchIcon {
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: rgb(124, 124, 124);
}

.searchInput {
    display: flex;
    border: 1px;
    border-style: solid;
    border-radius: 5px;
    border-color: rgb(161, 161, 161);            
    width: 30vw;
    min-width: 320px;
    margin-right: 1rem;
    transition: border-radius .5s;
    
    &:focus-within {
        box-shadow: 0 0 0 0.25rem rgb(233 233 233 / 50%);
        border-radius: 10px;
    }

    input {
        width: 100%;
        border-style: none;
        background-color: transparent;
        margin: 0;

        &:focus {
            box-shadow: none;
        }
    }
} 

.flexApart{
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    align-items: center;
    align-content: center;
}

.flexRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 1rem;
}